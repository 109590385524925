import request from '@/utils/request'

const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
// const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
// 平台用户列表 businessType string 1，创作者平台，2独立店，3商家
export function queryBusiness (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/business_manage/queryBusinessPage`,
    data
  })
}

// 详情-个人认证
export function queryBusinessById (data) {
  return request({
    url: `${baseUrl}/business_manage/queryBusinessDetail`,
    method: 'post',
    data
  })
}
// 用户状态 1-启用 0-禁用
export function updateStateBusiness (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/business_manage/updateState`,
    data
  })
}
