<!--
@author: binchen
@desc：上传图片
@date: 2023-04-25 22:12:40
-->
<template>
  <div class="uplaodComBox" :class="{ isRound }">
    <a-upload
      :list-type="listType"
      class="uplaodComBox-uploader"
      :multiple="multiple"
      :style="{ width, height }"
      :file-list="fileList"
      :show-upload-list="showUploadList"
      :remove="handleRmove"
      @preview="handlePreview"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <slot name="content" v-if="showBtn">
        <div :style="{ width, height }" class="uplaodComBox-inner">
          <img
            class="img"
            :style="{ width, height }"
            v-if="imageUrl"
            :src="ossUrl(imageUrl)"
            alt="avatar"
          />
          <div v-else>
            <a-icon v-if="showIcon" :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">{{ uploadText }}</div>
          </div>
        </div>
      </slot>
      <!-- <slot name="content" v-else>
        <a-button type="link">{{ uploadText }}</a-button>
      </slot> -->
    </a-upload>
    <div class="fixedMask" :style="{ display: isPreview ? 'block' : 'none' }">
      <img :src="ossUrl(previewImg)" alt="" />
      <a-icon
        type="close-circle"
        class="close-circle"
        @click="isPreview = false"
      />
    </div>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import * as OssClient from "@/components/AliOss/OssClientExCopy";
export default {
  name: "Uplaod",
  props: {
    isRound: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: [Boolean, String, Number],
      default: true,
    },
    content: {
      type: Boolean,
      default: true,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    showUploadList: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    uploadText: {
      type: String,
      default: "上传",
    },
    listType: {
      type: String,
      default: "picture-card",
    },
    name: {
      type: String,
      default: "avatar",
    },
    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "100px",
    },
    max: {
      type: Number,
      default: -1,
    },
    isCompress: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      fileList: [],
      imageUrl: null,
      loading: false,
      isPreview: false,
      previewImg: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    /**
     * 把oss上传的结果对象转换成upload组件用的对象结构
     * @param ossFileResult oss返回结构 {fileName,name:相对路径, url, res:{}}
     */
    convertOssToUpload(ossFileResult) {
      let uploadResult = {
        uid: ossFileResult.path, // upload组件记录唯一区分
        // url: ossFileResult.url, // TODO 这里两种都可以，但是采用同源后，需要使用ossUrl处理下
        url: this.ossUrl(ossFileResult.path),
        status: "done",
        name: ossFileResult.name, // 上传时候传入的fileName

        fileName: ossFileResult.name,
        uri: ossFileResult.path, // 上传之后的url的相对路径
        sort: 0, // 排序字段(默认就是数字下标排序)
        id: null, // id(动态：内容id是1对多),
        flag: 1, // flag :操作标记(1:新增 2:删除 3:无需处理)
      };
      return uploadResult;
    },

    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    async beforeUpload(file, fileList) {
      if (
        this.fileList.length + fileList.length > this.max &&
        this.max !== -1
      ) {
        return this.$message.info(`最多上传${this.max}张图片`);
      }
      if (file.size / 1024 / 1024 > this.maxSize && this.isCompress) {
        console.log("压缩");
        file = await this.compressImgae(file);
      }
      console.log(file, file.name);
      this.loading = true;
      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: "image",
      });
      if (!retResult.state) {
        this.$modal.alertError("上传处理失败：" + retResult.message);
        return false;
      }
      // 开始设置上传文件
      OssClient.uploadFile({
        bizType: "image",
        type: "file",
        fileName: file.name,
        file: file,
      })
        .then((result) => {
          console.log(result);
          let fileResult = this.convertOssToUpload(result);
          console.log(fileResult);
          this.imageUrl = fileResult.url;
          this.loading = false;
          this.$emit("success", fileResult.uri);
        })
        .catch((err) => {
          console.log("上传处理失败：", err);
          this.loading = false;
          this.$modal.alertError("上传处理失败：" + err);
        });
      return false;
    },
    async compressImgae(file) {
      console.log(file.size / 2);
      return new Promise((resolve, reject) => {
        imageConversion
          .compressAccurately(file, {
            size: Math.round(file.size / 1024 / 2),
            type: file.type,
            accuracy: 0.9,
            scale: 0.5,
          })
          .then((res) => {
            let blob = new Blob([res], {
              type: "text/plain;charset=utf-8",
            });
            let newFile = new File([blob], file.name, { type: blob.type });
            resolve(newFile);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 文件变更时间，进行上传处理
     */
    async handleChange({ file, uploadFileList }) {
      if (!file.status) {
        return;
      }
      if (file.status === "uploading") {
        this.loading = true;
      } else if (file.status === "done") {
        this.$message.error(`上传成功`);
        this.loading = false;
      } else if (file.status === "error") {
        this.loading = false;
        this.$message.error(`上传失败`);
      }
    },
    handlePreview(file) {
      console.log(file);
      this.previewImg = file.url;
      this.isPreview = true;
    },
    handleRmove(e) {
      this.fileList = this.fileList.filter((item) => item.uid !== e.uid);
      this.$emit("remove", e);
    },
  },
};
</script>
<style>
.uplaodComBox .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.isRound .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
}
.isRound .ant-upload.ant-upload-select-picture-card img {
  border-radius: 50%;
}
</style>
<style scoped lang="less">
.uplaodComBoxMore,
.uplaodComBox {
  position: relative;
  .img {
    max-width: 100%;
    max-height: 100%;
  }
}
.uplaodComBox-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload-text {
  font-size: 12px;
}
.fixedMask {
  background-color: #000000a6;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100000000;
  display: none;
  transition: all 0.5s;
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .close-circle {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 30px;
    color: #ffffff;
  }
}
</style>
