<template>
  <a-modal :title="form.userId ? '编辑品牌' : '创建品牌'" :footer="null" width="800px" @cancel="handleCancel" class="mymodal"
    :visible="visible">
    <div class="box">
      <a-form-model ref="form" :form="form" :model="form" :rules="rules" :colon="false" :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }">
        <a-form-model-item label="头像" prop="brandLogoPic">
          <Upload ref="brandLogoPic" isRound isCompress width="66px" height="66px"
            @success="(e) => uploadSuccess(e, 'brandLogoPic')">
          </Upload>
        </a-form-model-item>

        <a-form-model-item label="品牌名称" prop="brandName">
          <a-input style="width: 250px" v-model="form.brandName" placeholder="请输入" />
        </a-form-model-item>
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="8">
            <a-form-model-item label="商标注册证" prop="brandRegistPic" :label-col="{ span: 16 }" :wrapper-col="{ span: 8 }">
              <Upload ref="brandRegistPic" width="80px" height="80px"
                @success="(e) => uploadSuccess(e, 'brandRegistPic')">
              </Upload>
            </a-form-model-item>
          </a-col>
          <a-col class="gutter-row" :span="12">
            <a-form-model-item label="商标授权书" prop="brandAuthorizePic" :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }">
              <Upload ref="brandAuthorizePic" width="80px" height="80px"
                @success="(e) => uploadSuccess(e, 'brandAuthorizePic')" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-form-model-item label="绑定手机号" prop="bindPhone" v-if="!form.userId">
          <a-input style="width: 250px" v-model="form.bindPhone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="登录密码" prop="password" v-if="!form.userId">
          <a-input style="width: 250px" v-model="form.password" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="主页背景图" prop="bgm">
          <Upload ref="bgm" isCompress width="80px" height="80px" @success="(e) => uploadSuccess(e, 'bgm')" />
        </a-form-model-item>
        <a-form-model-item label="主页名称" prop="shopName">
          <a-input style="width: 250px" v-model="form.shopName" placeholder="请输入,可跟品牌名称一致" />
        </a-form-model-item>
        <a-form-model-item label="企业名称" prop="enterpriseName">
          <a-input style="width: 250px" v-model="form.enterpriseName" placeholder="请输入，品牌的企业名称" />
          <a-row :gutter="16" style="margin-top: 20px">
            <a-col class="gutter-row" :span="8">
              <Upload ref="busiLicensePic" width="153px" height="86px" :showIcon="false" uploadText="点击上传营业执照三证合一"
                @success="(e) => uploadSuccess(e, 'busiLicensePic')" />
            </a-col>

          </a-row>
        </a-form-model-item>

        <a-form-model-item label="社会统一信用代码" prop="busiLicenseCode">
          <a-input style="width: 250px" v-model="form.busiLicenseCode" placeholder="请输入，社会统一信用代码" />
        </a-form-model-item>
        <a-form-model-item label="营业执照有效期" prop="busiLicenseAvailable">
          <a-input style="width: 250px" v-model="form.busiLicenseAvailable" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="法人姓名" prop="legalmanLicenceName">
          <a-input v-model="form.legalmanLicenceName" placeholder="请输入" />
          <a-row :gutter="16" style="margin-top: 20px">
            <a-col class="gutter-row" :span="8">
              <Upload ref="legalmanLicenceHandpic" width="153px" height="86px" :showIcon="false"
                uploadText="点击上传手持身份证图片" @success="(e) => uploadSuccess(e, 'legalmanLicenceHandpic')" />
            </a-col>
            <a-col class="gutter-row" :span="8">
              <Upload ref="legalmanLicencePic" width="153px" height="86px" :showIcon="false" uploadText="点击上传身份证正面图片"
                @success="(e) => uploadSuccess(e, 'legalmanLicencePic')" />
            </a-col>
            <a-col class="gutter-row" :span="8">
              <Upload ref="legalmanLicenceBackpic" width="153px" height="86px" :showIcon="false"
                uploadText="点击上传身份证反面图片" @success="(e) => uploadSuccess(e, 'legalmanLicenceBackpic')" />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="简介" prop="intro">
          <div class="textareaBox">
            <textarea v-model="form.intro" placeholder="简介认证不超过100字符，4行，含标点符号" id="myTextarea" maxlength="100" rows="4"
              @input="limitInput"></textarea>
            <div class="tip">{{ form.intro.length }} / 100</div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="品牌主营" prop="brandMerito">
          <a-input style="width: 250px" v-model="form.brandMerito" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="资质实力" prop="bgm">
          <Upload class="shopEnvironment_modal" width="100%" height="100%" ref="abilityCertification" name="file" isMore
            multiple showUploadList isCompress @success="(e) => uploadSuccess(e, 'abilityCertification')">
            <div slot="content">点击上传(可上传多张)</div>
          </Upload>
        </a-form-model-item>
        <a-form-model-item label="品牌故事">
          <div class="textareaBox">
            <a-textarea v-model="form.brandStory" placeholder="请填写品牌故事" :maxLength="1000"
              :auto-size="{ minRows: 10 }"></a-textarea>
            <div class="tip">{{ form.brandStory.length }} / 1000</div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer">
      <a-button class="MR20" block @click="handleCancel">取消</a-button>
      <a-button type="primary" block :loading="confirmLoading" @click="handleOk">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
import BrandManageModel from '@/api/brandManage'
import Upload from '@/components/Upload'
export default {
  name: 'CreateBrandModal',
  components: { Upload },
  data () {
    let validatePass2 = (rule, value, callback) => {
      if (!this.form.legalmanLicenceName) {
        callback(new Error('请输入真实姓名'))
      } else if (!this.form.legalmanLicenceHandpic) {
        callback(new Error('请上传手持身份证图'))
      } else if (!this.form.legalmanLicencePic) {
        callback(new Error('请上传身份证正面'))
      } else if (!this.form.legalmanLicenceBackpic) {
        callback(new Error('请上传身份证反面'))
      } else {
        callback()
      }
    }
    let validatePass1 = (rule, value, callback) => {
      if (!value) {
        callback('请输入密码')
      } else if (value.length < 8) {
        callback(new Error('密码长度至少为8位数'))
      } else {
        callback()
      }
    }
    let validatePass3 = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/
      if (!value) {
        callback('请输入手机号')
      } else if (!reg.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    }
    let enterpriseName = (rule, value, callback) => {
      console.log(this.form.busiLicensePic)
      if (!value) {
        callback('请输入企业名称')
      } else if (!this.form.busiLicensePic) {
        callback(new Error('请上传营业执照三证合一'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      confirmLoading: false,
      form: {
        legalmanLicenceBackpic: '',
        brandName: '',
        bindPhone: '',
        bgm: '',
        shopName: '',
        brandAuthorizePic: '',
        legalmanLicenceHandpic: '',
        legalmanLicenceName: '',
        password: '',
        brandLogoPic: '',
        brandRegistPic: '',
        legalmanLicencePic: '',
        intro: '',
        brandStory: '',
        brandMerito: '',
        abilityCertification: []
      },
      rules: {
        brandLogoPic: [
          { required: true, message: '请上传头像', trigger: 'change' }
        ],
        brandName: [
          { required: true, message: '请输入品牌名', trigger: 'blur' }
        ],
        legalmanLicenceBackpic: [
          { required: true, message: '请上传身份证反面', trigger: 'change' }
        ],
        bindPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, validator: validatePass3, trigger: 'change' }
        ],
        shopName: [
          { required: true, message: '请输入主页名称', trigger: 'blur' }
        ],
        enterpriseName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { required: true, validator: enterpriseName, trigger: 'change' }
        ],
        busiLicenseCode: [
          { required: true, message: '请输入社会统一信用代码', trigger: 'blur' }
        ],
        busiLicenseAvailable: [
          { required: true, message: '请输入营业执照有效期', trigger: 'blur' }
        ],
        brandAuthorizePic: [
          { required: true, message: '请上传商标授权书', trigger: 'change' }
        ],
        legalmanLicenceHandpic: [
          { required: true, message: '请上传手持身份证图', trigger: 'change' }
        ],
        legalmanLicenceName: [
          { required: true, validator: validatePass2, trigger: 'change' }
        ],
        password: [
          { required: true, validator: validatePass1, trigger: 'change' }
        ],
        intro: [{ required: true, message: '请输入简介', trigger: 'blur' }],
        brandRegistPic: [
          { required: true, message: '请上传商标注册证', trigger: 'change' }
        ],
        legalmanLicencePic: [
          { required: true, message: '请上传身份证正面', trigger: 'change' }
        ]
      }
    }
  },
  mounted () { },
  methods: {
    onShow (data = null) {
      this.visible = true
      if (data) {
        // this.form = { ...data }
        this.getDetailByUserId(data.userId)
      } else {
      }
    },
    getDetailByUserId (userId) {
      BrandManageModel.detail({ userId }).then((res) => {
        const {
          authentication,
          personalResp,
          business,
          realBusiCertification
        } = res.data
        let brandLogoPic = authentication.brandCertification.brandLogoPic
        let brandName = authentication.brandCertification.brandName
        let brandRegistPic = authentication.brandCertification.brandRegistPic
        let busiLicensePic = realBusiCertification.busiLicensePic
        let brandAuthorizePic =
          authentication.brandCertification.brandAuthorizePic
        let bindPhone = personalResp.phone
        let password = personalResp.password
        let bgm = business.bgm
        let shopName = business.shopName
        let brandMerito = business.brandMerito
        let legalmanLicenceBackpic = realBusiCertification.legalmanLicenceBackpic
        let legalmanLicencePic = realBusiCertification.legalmanLicencePic
        let legalmanLicenceHandpic = realBusiCertification.legalmanLicenceHandpic
        let abilityCertification = authentication.abilityCertification
        let enterpriseName = realBusiCertification.enterpriseName
        let busiLicenseCode = realBusiCertification.busiLicenseCode
        let busiLicenseAvailable = realBusiCertification.busiLicenseAvailable
        let legalmanLicenceName = realBusiCertification.legalmanLicenceName
        let brandStory = authentication.introduction
        let intro = business.describe
        this.form = {
          userId: personalResp.userId,
          businessUserId: business.userId,
          legalmanLicenceBackpic: legalmanLicenceBackpic,
          brandName: brandName,
          bindPhone: bindPhone,
          bgm: bgm,
          shopName: shopName,
          brandAuthorizePic: brandAuthorizePic,
          legalmanLicenceHandpic: legalmanLicenceHandpic,
          legalmanLicenceName,
          password: password,
          brandLogoPic: brandLogoPic,
          brandRegistPic: brandRegistPic,
          legalmanLicencePic: legalmanLicencePic,
          intro,
          brandStory,
          brandMerito,
          enterpriseName: enterpriseName,
          busiLicenseAvailable,
          busiLicenseCode,
          abilityCertification: abilityCertification,
          busiLicensePic
        }
        console.log(this.form)
        this.$nextTick(() => {
          this.$refs.bgm.imageUrl = bgm
          this.$refs.bgm.fileList = [
            {
              uid: Math.random(),
              name: bgm.split('.')[1],
              status: 'done',
              url: this.ossUrl(bgm),
              value: bgm
            }
          ]
          this.$refs.abilityCertification.fileList = abilityCertification.map(
            (item) => {
              return {
                uid: Math.random(),
                name: item.split('.')[1],
                status: 'done',
                url: this.ossUrl(item),
                value: item
              }
            }
          )
          this.$refs.brandLogoPic.imageUrl = brandLogoPic
          this.$refs.brandLogoPic.fileList = [
            {
              uid: Math.random(),
              name: brandLogoPic.split('.')[1],
              status: 'done',
              url: this.ossUrl(brandLogoPic),
              value: brandLogoPic
            }
          ]
          this.$refs.busiLicensePic.imageUrl = busiLicensePic
          this.$refs.busiLicensePic.fileList = [
            {
              uid: Math.random(),
              name: busiLicensePic.split('.')[1],
              status: 'done',
              url: this.ossUrl(busiLicensePic),
              value: busiLicensePic
            }
          ]
          this.$refs.legalmanLicenceBackpic.imageUrl = legalmanLicenceBackpic
          this.$refs.legalmanLicenceBackpic.fileList = [
            {
              uid: Math.random(),
              name: legalmanLicenceBackpic.split('.')[1],
              status: 'done',
              url: this.ossUrl(legalmanLicenceBackpic),
              value: legalmanLicenceBackpic
            }
          ]
          this.$refs.legalmanLicencePic.imageUrl = legalmanLicencePic
          this.$refs.legalmanLicencePic.fileList = [
            {
              uid: Math.random(),
              name: legalmanLicencePic.split('.')[1],
              status: 'done',
              url: this.ossUrl(legalmanLicencePic),
              value: legalmanLicencePic
            }
          ]
          this.$refs.legalmanLicenceHandpic.imageUrl = legalmanLicenceHandpic
          this.$refs.legalmanLicenceHandpic.fileList = [
            {
              uid: Math.random(),
              name: legalmanLicenceHandpic.split('.')[1],
              status: 'done',
              url: this.ossUrl(legalmanLicenceHandpic),
              value: legalmanLicenceHandpic
            }
          ]
          this.$refs.brandRegistPic.imageUrl = brandRegistPic
          this.$refs.brandRegistPic.fileList = [
            {
              uid: Math.random(),
              name: brandRegistPic.split('.')[1],
              status: 'done',
              url: this.ossUrl(brandRegistPic),
              value: brandRegistPic
            }
          ]
          this.$refs.brandAuthorizePic.imageUrl = brandAuthorizePic
          this.$refs.brandAuthorizePic.fileList = [
            {
              uid: Math.random(),
              name: brandAuthorizePic.split('.')[1],
              status: 'done',
              url: this.ossUrl(brandAuthorizePic),
              value: brandAuthorizePic
            }
          ]
        })
      })
    },
    handleOk () {
      this.form.abilityCertification =
        this.$refs.abilityCertification.fileList.map((item) => item.value)
      this.$refs.form.validate((valid) => {
        if (valid) {
          BrandManageModel[this.form.userId ? 'update' : 'create'](
            this.form
          ).then((res) => {
            if (res.code === 0) {
              this.visible = false
              this.handleCancel()
              this.$message.success(res.message)
              this.$emit('success')
            }
          })
        } else {
          return false
        }
      })
    },
    handleCancel () {
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          const ref = this.$refs[key]
          if (ref.fileList && ref.fileList.length) {
            ref.fileList = []
            ref.imageUrl = ''
          }
        }
      }
      this.form = {
        legalmanLicenceBackpic: '',
        brandName: '',
        bindPhone: '',
        bgm: '',
        shopName: '',
        brandAuthorizePic: '',
        legalmanLicenceHandpic: '',
        legalmanLicenceName: '',
        password: '',
        brandLogoPic: '',
        brandRegistPic: '',
        legalmanLicencePic: '',
        intro: '',
        brandStory: '',
        brandMerito: '',
        abilityCertification: []
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    uploadSuccess (e, type) {
      let file = {
        uid: Math.random(),
        name: e.split('.')[1],
        status: 'done',
        url: this.ossUrl(e),
        value: e
      }
      if (type === 'abilityCertification') {
        this.$refs[type].fileList.push(file)
      } else {
        this.$refs[type].fileList = [file]
      }

      this.form[type] = e
      if (
        this.form.legalmanLicenceHandpic &&
        this.form.legalmanLicenceBackpic &&
        this.form.legalmanLicencePic &&
        this.form.legalmanLicenceName
      ) {
        this.$refs.form.validateField('legalmanLicenceName')
      } else {
        this.$refs.form.validateField(type)
      }
      if (
        [
          'legalmanLicenceHandpic',
          'legalmanLicenceBackpic',
          'legalmanLicencePic',
          'legalmanLicenceName'
        ].includes(type)
      ) {
        if (
          this.form.legalmanLicenceHandpic &&
          this.form.legalmanLicenceBackpic &&
          this.form.legalmanLicencePic &&
          this.form.legalmanLicenceName
        ) {
          this.$refs.form.validateField('legalmanLicenceName')
        }
      } else {
        this.$refs.form.validateField(type)
      }
    },
    handleRemoveImg (type) {
      this.form[type] = null
    },
    limitInput (textarea) {
      console.log(textarea)
      // 限制字符数为 100
      const maxLength = 100
      if (textarea.target.value.length > maxLength) {
        textarea.value = textarea.target.value.slice(0, maxLength)
      }

      // 限制行数为 4
      const maxRows = 4
      const rows = textarea.target.value.split('\n').length
      if (rows > maxRows) {
        const lastIndex = textarea.target.value.lastIndexOf('\n')
        textarea.target.value = textarea.target.value.slice(0, lastIndex)
      }
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 100%;
  height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}

#myTextarea {
  width: 100%;
  padding-bottom: 10px;
  border: 0.5px solid #d9d9d9;
  outline-color: #d9d9d9;
}

#myTextarea:focus {
  outline-color: #40a9ff;
}

#myTextarea:hover {
  outline-color: #40a9ff;
}

.textareaBox {
  position: relative;

  .tip {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }
}

.imgBox {
  width: 153px;
  height: 86px;
  line-height: 86px;
  font-size: 13px;

  img {
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
  }
}

.shopEnvironment_modal {

  /deep/.ant-upload-list-picture-card .ant-upload-list-item,
  /deep/ .ant-upload-list-picture-card-container {
    width: 80px;
    height: 80px;
  }

  /deep/.ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
  }
}
</style>