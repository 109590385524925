import request from "@/utils/request";
const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
class BrandManageModel {
  list(data) {
    return request({
      method: "post",
      url: `${baseUrl}/brand/queryBrandPage`,
      data,
    });
  }
  detail(data) {
    return request({
      method: "post",
      url: `${baseUrl}/business_manage/queryBusinessDetail`,
      data,
    });
  }
  create(data) {
    return request({
      method: "post",
      url: `${baseUrl}/brand/create-brand-business`,
      data,
    });
  }
  update(data) {
    return request({
      method: "post",
      url: `${baseUrl}/brand/edit-brand-business`,
      data,
    });
  }
  bindShop(data) {
    return request({
      method: "post",
      url: `${baseUrl}/brand/bindStoresPage`,
      data,
    });
  }
}
export default new BrandManageModel();
