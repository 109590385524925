<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <a-card style="margin-bottom: 20px">
      <a-form-model ref="form" class="ak-query-filter myform" :model="searchForm" :colon="false">
        <a-row :gutter="20">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="手机号" prop="phone">
              <a-input v-model.trim="searchForm.phone" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="品牌名称" prop="shopName">
              <a-input v-model.trim="searchForm.shopName" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="管理员昵称" prop="nickName">
              <a-input v-model.trim="searchForm.nickName" placeholder="请输入" />
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-row :gutter="20" class="mt20">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="状态" prop="state">
              <ak-select :loadData="$const.STATUS" allowClear v-model="searchForm.state" placeholder="请选择" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="入驻时间" prop="date">
              <a-range-picker v-model="date" show-time class="W100" :placeholder="['请选择', '请选择']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="changeDate" />
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <div style="text-align:right">
              <a-button type="primary" class="ML12" @click="search">查询</a-button>
              <a-button class="ML12" @click="onResetForm">重置</a-button>
            </div>

          </a-col>

        </a-row>

      </a-form-model>
      <a-button type="primary" class="MT20" @click="createBrand">创建品牌</a-button>
    </a-card>

    <a-table :pagination="{
         total: total,
         current:searchForm.page,
         pageSize: searchForm.pageSize, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
    }" :columns="columns" :data-source="tableData" :scroll="{ x: 100,y:850 }" :rowKey="(record) => record.id" @change="handleTableChange" :loading="loading">
      <template #action="text, record">
        <router-link class="MR10" :to="`/user-manage/brandManage/detail?userId=${record.userId}`">
          详情
        </router-link>
        <a-button v-if="record.state == 1" type="link" @click="handleForbidden(record)">禁用</a-button>
        <a-button v-else type="link" @click="handleForbidden(record)">正常</a-button>
        <a-button type="link" @click="editBrand(record)">编辑</a-button>
      </template>
      <template #brandLogoPic="text">
        <img class="logo_img" :src="ossUrl(text)">
      </template>
      <template #state="text">
        <code-tag :codeList="$const.STATUS" :code="text"></code-tag>
      </template>
      <template #bindShopNum="text,record">
        <a-button type="link" @click="openBindShop(record)">{{ text }}</a-button>
      </template>
      <template #personalResp="text,record">
        <div class="personalResp">
          <img class="logo_img" :src="ossUrl(record.personalResp.headImage)">
          <div class="right">
            <div class="name">{{ record.personalResp.name }}</div>
            <div class="phone">{{ record.personalResp.phone }}</div>
          </div>
        </div>
      </template>
    </a-table>
    <CreateBrandModal ref="createBrand" @success="initData" />
    <bind-shop ref="bindShop"></bind-shop>
  </ak-container>
</template>

<script>
const columns = [
  {
    title: '品牌LOGO',
    dataIndex: 'brandLogoPic',
    width: 80,
    scopedSlots: { customRender: 'brandLogoPic' }
  },
  {
    title: '品牌名称',
    dataIndex: 'brandName'
  },
  {
    title: '绑定管理员',
    dataIndex: 'personalResp',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'personalResp' }
  },
  {
    title: '状态',
    dataIndex: 'state',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '最后IP属地',
    key: 'ipLocation',
    dataIndex: 'ipLocation'
  },
  {
    title: '品牌绑定门店数',
    dataIndex: 'bindShopNum',
    scopedSlots: { customRender: 'bindShopNum' }
  },
  {
    title: '最后登录时间',
    dataIndex: 'lastLoginTime'
  },
  {
    title: '入驻时间',
    dataIndex: 'createdTime'
  },

  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
    width: 240
  }
]
import * as Business from '@/api/user/business'
import BrandManageModel from '@/api/brandManage'
import CreateBrandModal from './createBrand.vue'
import BindShop from './BindShop.vue'
export default {
  components: { CreateBrandModal, BindShop },
  data() {
    return {
      date: [],
      total: 0,
      loading: false,
      // 查询参数
      searchForm: {
        page: 1,
        pageSize: 10
      },
      columns,

      tableData: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current
      this.searchForm.pageSize = pagination.pageSize
      this.initData()
    },
    // 加载数据方法
    initData() {
      this.loading = true
      BrandManageModel.list(this.searchForm)
        .then((res) => {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    search() {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this.initData()
    },
    // 重置
    onResetForm() {
      this.$refs.form.resetFields()
      this.date = []
      this.searchForm = this.$options.data.call(this).searchForm
      this.initData()
    },
    handleForbidden(record) {
      if (record.state == 1) {
        this.$confirm({
          title: '是否禁用该品牌?',
          content:
            '禁用状态下，品牌不能登录自己的账号账号的内容可以不能被搜索到',
          onOk: () => {
            Business.updateStateBusiness({
              userId: record.userId,
              state: 0
            }).then((res) => {
              console.log(res)
              if (res.code == 0) {
                this.$message.success('状态修改成功')
                this.initData()
              }
            })
          },
          onCancel() {}
        })
      } else {
        this.$confirm({
          title: '是否设置品牌为正常?',
          content: '正常状态下，用户可以登录自己的账号账号的内容可以被搜索到',
          onOk: () => {
            Business.updateStateBusiness({
              userId: record.userId,
              state: 1
            }).then((res) => {
              console.log(res)
              if (res.code == 0) {
                this.$message.success('状态修改成功')
                this.initData()
              }
            })
          },
          onCancel() {}
        })
      }
    },
    changeDate(e) {
      if (e) {
        this.searchForm.createdTimeStart = e[0]
        this.searchForm.createdTimeEnd = e[1]
      }
    },
    createBrand() {
      this.$refs.createBrand.onShow()
    },
    editBrand(row) {
      this.$refs.createBrand.onShow(row)
    },
    openBindShop(record) {
      this.$refs.bindShop.onShow(record)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.myform .ant-form-item-label {
  width: 85px !important;
}
.ak-card-table {
  padding: 20px;
  background: #fff;
}

/deep/ .ant-card-body {
  padding: 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
.logo_img {
  width: 60px;
  height: 60px;
}
.personalResp {
  display: flex;
  align-items: center;
  text-align: right;
  .right {
    margin: 10px;
  }
}
</style>
