<template>
  <a-modal title="品牌绑定门店" :visible="visible" width="80%" @cancel="visible = false">
    <a-form-model ref="form" layout="inline" :form="form" :model="form">
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input style="width: 250px" v-model.trim="form.shopName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone">
        <a-input style="width: 250px" v-model.trim="form.phone" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">查询</a-button>
        <a-button class="ML10" @click="onResetForm">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-table class="MT20" :pagination="pagination" :columns="columns" :data-source="tableData" :scroll="{ x: 100 }" :rowKey="(record) => record.id" @change="changePage" :loading="loading">
      <template #storesType="text">
        <code-tag :codeList="$const.STORETYPE" :code="text"></code-tag>
      </template>
      <template #logo="text">
        <img class="logo_img" :src="ossUrl(text)">
      </template>
      <template #shopName="text">
        <div>{{ text }}</div>
      </template>
      <template #personalResp="text,record">
        <div class="personalResp">
          <img class="logo_img" :src="ossUrl(record.personalResp.headImage)">
          <div class="right">
            <div class="name">{{ record.personalResp.name }}</div>
            <div class="phone">{{ record.personalResp.phone }}</div>
          </div>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>
  
  <script>
import BrandManageModel from '@/api/brandManage'
import Upload from '@/components/Upload'
const columns = [
  {
    title: '店铺LOGO',
    dataIndex: 'logo',
    scopedSlots: { customRender: 'logo' }
  },
  {
    title: '店铺名称',
    dataIndex: 'shopName',
    width: 200,
    scopedSlots: { customRender: 'shopName' }
  },
  {
    title: '绑定运营人',
    dataIndex: 'personalResp',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'personalResp' }
  },
  {
    title: '门店类型',
    dataIndex: 'storesType',
    align: 'center',
    scopedSlots: { customRender: 'storesType' }
  },
  {
    title: '门店浏览次数',
    key: 'browseCount',
    dataIndex: 'browseCount'
  },
  {
    title: '门店分享次数',
    dataIndex: 'shareCount'
  },
  {
    title: '点击联系次数',
    dataIndex: 'contactCount'
  },
  {
    title: '得到联系方式次数',
    dataIndex: 'contactSuccessCount'
  }
]
export default {
  name: 'BindShop',
  components: { Upload },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        shopName: '',
        phone: '',
        page: 1,
        pageSize: 10
      },
      columns,
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据` // 分页中显示总的数据
      },
      userId: null
    }
  },
  mounted() {},
  methods: {
    onShow(record) {
      this.visible = true
      this.form = {
        shopName: '',
        phone: '',
        page: 1,
        pageSize: 10
      }
      this.userId = record.userId

      this.initData()
    },
    handleSearch() {
      this.form.pageSize = 10
      this.form.pageNum = 1
      this.initData()
    },
    // 重置
    onResetForm() {
      this.$refs.form.resetFields()
      this.form = this.$options.data.call(this).form
      this.initData()
    },
    initData() {
      this.loading = true
      this.form.brandUserId = this.userId
      BrandManageModel.bindShop(this.form)
        .then((res) => {
          this.tableData = res.data.list
          this.pagination.total = res.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(pagination) {
      this.form.page = pagination.current
      this.form.pageSize = pagination.pageSize
      this.initData()
    }
  }
}
</script>
  
  <style scoped lang="less">
.logo_img {
  width: 60px;
  height: 60px;
}
.personalResp {
  display: flex;
  align-items: center;
  text-align: right;
  .right {
    margin: 10px;
  }
}
</style>